export const NetworkContextName = "OI MainNet";

export const ACTIVE_NETWORK = 5656; //mainet
export const chainId = "0x1618"; //mainet

// export const ACTIVE_NETWORK = 565656; // testNet
// export const chainId = "0x8A198"; //mainet

export const stakingAddress = "0x0000000000000000000000000000000000001001";
export const verifyStake = "0xd9Cac2263325649ade6A2f1bE267BDA6EE195542";

export const RewardDistributionAddress =
  "0x4ffAb552271cD837dFaBca4782c0Ac4F9B894c17"; // new one mainnet 12--

// export const RewardDistributionAddress =
//   "0xc0cEF1f87767AF827FA6ee05D6c056cE9Bc2DFEd"; //testing new one 5656

// export const RewardDistributionAddress =
//   "0x07A892222d78AfB8b22809c85eC3Ab5084431D4a"; //testing 565656
// export const RewardDistributionAddress =
//   "0x2438C6cA04122f7d74e555e9EFdE1C86266CC4B7"; //old one

export const RPC_URL = "https://rpc-main1.qiblockchain.online/"; //mainNet
// export const RPC_URL = "https://testnet-main1.qiblockchain.online/"; // test net

export const default_RPC_URL = RPC_URL;
export const explorerURL =
  "https://mainnet.qiblockchain.online/explorer/explorer";

export const NetworkDetails = [
  {
    chainId: chainId,
    chainName: "QIE Mainnet",
    nativeCurrency: {
      name: "QIE",
      symbol: "QIE",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: [explorerURL],
  },
];
