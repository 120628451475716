import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import toast from "react-hot-toast";
import Web3 from "web3";
import { qieWalletConnector } from "src/connectors/QieConnector";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, deactivate, account, chainId, active, library, connector } =
    useWeb3React();
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [totalValidator, setTotalValidator] = useState(100);
  const [walletLoading, setWalletLoading] = useState(false);
  const [userData] = useState({});
  const provider = library?.provider; // Directly get the provider
  const web3 = provider ? new Web3(provider) : null; // Initialize Web3 if provider is available

  const connectWallet = async (walletName) => {
    try {
      setWalletLoading(true);

      const activateWallet =
        walletName === "qie" ? qieWalletConnector : injected;

      await activate(activateWallet, undefined, true)
        .then(() => {
          window.localStorage.setItem("walletName", walletName);
          setOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
          console.error("ERROR", error);
        });

      setWalletLoading(false);
    } catch (error) {
      setWalletLoading(false);
      console.error("Error connecting to wallet:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      connectWallet(localStorage.getItem("walletName"));
    }
  }, []);

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        swichNetworkHandler();
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${parseInt(ACTIVE_NETWORK.toString(16), 10).toString(
              16
            )}`,
          },
        ],
      });
    } catch (error) {
      toast.error(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      const walletName = localStorage.getItem("walletName");
      await provider.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };

  const disconnectWallet = () => {
    try {
      deactivate();
      window.localStorage.removeItem("walletName");
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
      toast.error("Failed to disconnect wallet.");
    }
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    account,
    open,
    provider,
    web3,
    setOpen: (e) => setOpen(e),
    totalValidator,
    setTotalValidator,
    isCorrectNetwork: chainId === ACTIVE_NETWORK ? true : false,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    connectWallet: connectWallet,
    disconnectWallet: disconnectWallet,
    swichNetworkHandler: () => swichNetworkHandler(),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
