import { AbstractConnector } from "@web3-react/abstract-connector";

class QIEWalletConnector extends AbstractConnector {
  constructor() {
    super({ supportedChainIds: [1, 56, 97, 137, 5656] }); // Ethereum, BSC, Polygon
  }

  async activate() {
    if (!window.qie) {
      throw new Error("QIE Wallet is not installed.");
    }
    return {
      provider: window.qie,
      chainId: await window.qie.request({ method: "eth_chainId" }),
      account: (await window.qie.request({ method: "eth_accounts" }))[0],
    };
  }

  async getProvider() {
    return window.qie;
  }

  async getChainId() {
    return await window.qie.request({ method: "eth_chainId" });
  }

  async getAccount() {
    return (await window.qie.request({ method: "eth_accounts" }))[0];
  }

  async deactivate() {
    console.log("QIE Wallet disconnected.");
  }
}

export const qieWalletConnector = new QIEWalletConnector();
